import router from '@/router';
import {  Vue, Options } from 'vue-class-component';
import SparePartService from '../../services/spare-parts.service';

const sparepartService = new SparePartService();

export default class SparePartsLocationChange extends Vue{
    
    public form = {
        location: '' as string,

    };
    public current_location = '';
    public errorMessage = '';

    mounted() {
        this.containerId();
        this.location();
        const loc: any = this.$refs.location
        loc.focus()
        
        
      }

      containerId(){
        if(localStorage.getItem('container_id') != null){
            return localStorage.getItem('container_id')
        }else{
            return "Invalid"
        }
        
    }

        
    location(){
        if(localStorage.getItem('current_location') != null){
            return localStorage.getItem('current_location')
        }else{
            return "Invalid"
        }
    }
    
    changeLocation(){
        this.$store.state.Loading = true;
        const locationRegex = /^\s*[a-zA-Z0-9][a-zA-Z0-9\s]*$/

      if (!locationRegex.test(this.form.location)) {
            this.$store.state.Loading = false;
            this.errorMessage = 'Enter valid Location';
            console.log(this.errorMessage)
            router.push('/spare-parts-location-change')
      } else {
        const request = this.form
        const id = localStorage.getItem('container_id')
        sparepartService.ChangeLocation(id, request).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
				// console.log(res)
                router.push('/spare-parts-id-location')
            },(err: any) => {
                this.$store.state.Loading = false;
                this.errorMessage = 'Enter valid Location';
                console.log(this.errorMessage)
              }
        )
      }
        
        
    }

    created() {
        const id = localStorage.getItem('container_id')
        sparepartService.ContainerIdCheck(id).then(
            (response: any) =>{
                this.$store.state.Loading = false;
                const res = response.data;
                this.current_location = res.data.location
                // console.log(this.current_cage)
				// console.log(res)
                
                localStorage.setItem('current_location', this.current_location)
             
            }
        )
    }

}